.LabeledTextInputLabel {
  font-size: 16px;
  text-align: start;
  font-weight: bold;
  margin-bottom: 12px;
}

.LabeledTextInput {
  justify-self: stretch;
  border: 1px solid black;
  border-radius: 5px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

.LabeledDateInput {
  justify-self: stretch;
  border: 1px solid black;
  border-radius: 5px;
  height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

.PhotoUploadCarouselContainer {
  width: 100%;
  overflow-x: scroll;
  
}

.PhotoUploadCarousel {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.PhotoUploadSinglePhoto {
  position: relative;
}

.PhotoUploadPhoto {
  max-height: 180px;
}

.PhotoUploadClose {
  flex: 1;
  border: 1px solid #ff6969;
  background-color: white;
  position: absolute;
  width: 22px;
  height: 22px;
  right: 0;
  top: 0;
  cursor: pointer;
}

/* PhotoUploadClose minus all the shenanigans to be overlaid on an image */
.EditableListClose {
  flex: 1;
  background-color: white;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.PhotoUploadClose:hover {
  background-color: #ff6969;
}

.PhotoUploadCloseImage {
  width: 20px;
  height: 20px;
}

.PhotoUploadAdd {
  display: flex;
  min-width: 180px;
  width: 180px;
  height: 180px;
  background: none;
  cursor: pointer;
  border: 1px solid black;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

.PhotoUploadAdd img {
  width: 40px;
  height: 40px;
}

.PhotoUploadAddButton {
  /*justify-self: stretch;*/
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
  height: 50px;
  padding: 13px;
  font-size: 16px;
}

.TimeZoneInputContainer {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0;
}

.LabeledTextAreaInput {
  justify-self: stretch;
  font-family: 'Nunito', sans-serif;
  border: 1px solid black;
  border-radius: 5px;
  padding: 14px;
  font-size: 16px;
  resize: none;
}

/* needs to exact match LabeledTextInput */
.PhoneInputInput {
  justify-self: stretch;
  border: 1px solid black;
  border-radius: 5px;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

/*@media (prefers-color-scheme: dark) {*/
/*  .LabeledTextInput {*/
/*    background-color: #423E3A;*/
/*    color: white;*/
/*    border-color: white;*/
/*  }*/
/**/
/*  .PhoneInputInput {*/
/*    background-color: #423E3A;*/
/*    color: white;*/
/*    border-color: white;*/
/*  }*/
/*}*/

.TeamMemberEducationSectionTopRow {
  display: flex;
  align-items: center;
}

.TeamMemberEducationList {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  gap: 50px;
}

.LabeledInputError {
  font-size: 14px;
  text-align: start;
  color: red;
}

.TextInputAddItem {
  flex: 1;
  text-align: end;
}

.SearchResultOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  z-index: 100000;
}

.SearchResult {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 400px;
  text-align: left;
}

.SearchResult:hover {
  background-color: #F6F4F1;
}