.SurveySection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-self: center;
  row-gap: 20px;
  padding: 20px;
  font-family: 'Nunito', sans-serif;
  width: min(700px, 100vw);
}

.SurveyProgressBar {
  display: flex;
  width: 100%;
  height: 10px;
  flex-direction: row;
  justify-content: stretch;
}

.SurveyProgressBarItem {
  height: 10px;
  flex: 1;
}

.SurveyProgressBarItemSelected {
  background-color: #96695e;
}

.SurveyProgressBarItemNotSelected {
  background-color: #96695e80;
}

.SurveyTitle {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.SurveySubtitle {
  text-align: center;
  font-size: 16px;
  color: #423e3a;
  font-style: italic;
}

.SurveyContinueButton {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 25pt;
  padding: 10px 40px 10px 40px;
}

.SurveyBackButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 20px;
  margin-top: 30px;
  padding: 15px;
  background-color: white;
  color: gray;
  border: 1px solid lightgray;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  overflow: hidden;
}

.SurveyHeader {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  gap: 20px;
}

.SurveyErrorHeader {
  display: flex;
  justify-content: end;
}

.SurveyHeaderLeft {
  flex: 1;
  color: #423E3A;
  text-align: left;
}

.SurveyHeaderButtonContainer {
  flex: 0.5;
  display: flex;
}

.SurveyError {
  color: red;
  font-family: 'Nunito', sans-serif;
  font-size: 14pt;
  padding: 5px;
}

.survey-checklist {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 20px;
  text-align: start;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 2px solid #96695e;
  border-radius: 5px;
  overflow: hidden;
}

.selected {
  background-color: #96695e;
  color: white;
}

.notSelected {
  background-color: white;
}

/*
 * SplashPageContainer and SplashPageContent are used for the "Welcome to Kensu" splash
 * page when redeeming invites, and also some of the survey content; they are centered
 * pages with a vertical scroll and a max width of 700px;
 */
.SplashPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.SplashPageContent {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: min(700px, 100vw);
}

.SurveyOpeningPage {
  padding: 20px;
  display: flex;
  flex: 1;
  align-self: center;
  flex-direction: column;
  text-align: left;
  width: min(700px, 100vw);
}

.SurveySignature {
  font-family: 'Parisienne', serif;
  font-size: larger;
}

.NewSurveyContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

table.SurveyExperienceTable, th.SurveyExperienceTable, td.SurveyExperienceTable {
  border: 0;
  border-spacing: 20px;
  font-size: 20px;
}


hr {
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid  #a6a6a6;
}