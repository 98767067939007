table.VersionHistoryTable, th.VersionHistoryTable, td.VersionHistoryTable {
  border: 0.5px solid #a6a6a6;
  border-spacing: 0;
}

.VersionHistoryTable {
  font-size: 16px;
  border-spacing: 0;
}

.VersionHistoryTable th {
  background-color: #96695e;
  margin: 0;
  padding: 10px 25px 10px 20px;
  color: white;
  /*border: 1px solid #a6a6a6;*/
}

.VersionHistoryTable td {
  text-align: center;
  padding: 10px 25px 10px 20px;
  /*border: 1px solid #a6a6a6;*/
}

.VersionHistoryTable a {
  text-decoration: underline;
}

.VersionHistoryTable .SubmitButton {
  font-family: 'Nunito', sans-serif;
  background-color: white;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.VersionHistoryTable .SubmitButton:hover {
  color: white;
  background-color: black;
}

.VersionHistoryTable .LiveVersion {
  font-weight: bold;
}