.VerticalFlex {
  display: flex;
  flex-direction: column;
}

.CreateProfileCheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 16px;
}

.CreateProfileCheckbox {
  width: 24px;
  height: 24px;
  border: 1px solid black;
  border-radius: 10px;
  /*-moz-appearance:none;*/
  /*-webkit-appearance:none;*/
}

.CreateProfileSpinner {
  width: 18px;
  height: 18px;
  filter: invert(100%);
}

/*@media (prefers-color-scheme: dark) {*/
/*  .CreateProfileSpinner {*/
/*    filter: invert(0%);*/
/*  }*/
/*}*/

