.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
  padding: 28px;
  border-radius: 5px;
  max-height: 90vh;
  max-width: 90vw;
  overflow: auto;
}

.WeeklyRepeatPeriod {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.WeeklyRepeatPeriod input {
  width: 50px;
  padding: 5px;
}

.WeeklyRepeatContainer {
  display: flex;
  gap: 10px;
  padding: 10px;
  justify-content: space-evenly;

}

.WeeklyRepeatItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CalendarLeftNav {
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 15px;
}

.CalendarLeftNav h4 {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 0;
}

.CalendarTeamMemberContainer {
  cursor: pointer;
  display: flex;
  margin-top: 10px;
  gap: 10px;
}

.CalendarTeamMemberCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid;
}

.AppointmentTypeCheckbox {
  width: 16px;
  height: 16px;
  margin: 1px;
}

.rbc-timeslot-group {
  min-height: 60px;
}

.rbc-day-slot .slot-disabled {
  background-color: #eee;
}
